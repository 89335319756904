<template>

    <div class="control-section">
        <div class="control-wrapper">
            <div class="sample-container">
                <div class="default-section">
                    <ejs-richtexteditor
                        v-model="inputVal"
                        :change="validate"
                        :create="onCreate"
                        :toolbarSettings="toolbarEnabled ? toolbarSettings : disabledToolbarSettings"
                        :ref="name"
                        :name="name"
                        :enabled="enabled"
                        :enableHtmlEncode="enableHtmlEncode"
                        :readonly="readOnly"
                        :showCharCount="showCharCount"
                        :maxLength="maxLength"
                        :insertImageSettings="insertImageSettings"
                        :imageUploading="onImageUploading"
                        :imageUploadSuccess="onImageUploadSuccess"
                    >
                    </ejs-richtexteditor>
                    <div class="error-element"></div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import Vue from "vue";
import { RichTextEditorPlugin, Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar, Table } from "@syncfusion/ej2-vue-richtexteditor";
import { CheckBoxPlugin, ButtonPlugin } from "@syncfusion/ej2-vue-buttons";
import { NumericTextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
import { FormValidator } from '@syncfusion/ej2-inputs';
import i18n from '@/shared/plugins/vue-i18n';

Vue.use(RichTextEditorPlugin);
Vue.use(CheckBoxPlugin);
Vue.use(ButtonPlugin);
Vue.use(NumericTextBoxPlugin);

export default {
    name: 'StTextEditor',
    props: {
        showCharCount: {
            type: Boolean,
            default: true,
        },
        maxLength: {
            type: Number,
            default: 1000,
        },
        enabled: {
            type: Boolean,
            default: true,
        },
        toolbarEnabled: {
            type: Boolean,
            default: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        enableHtmlEncode: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            required: false,
        },
        formId: {
            type: String,
            required: true,
        },
        uploadToken: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            default: 'rteInstance'
        },
        isRequired: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            formObject: null,
            toolbarSettings: {
                type: 'MultiRow',
                items: ['Bold', 'Italic', 'Underline',
                'FontSize', 'FontColor',
                'LowerCase', 'UpperCase', '|',
                'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
                'CreateTable', '|',
                'CreateLink', 'Image', '|',
                'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
            ]},
            disabledToolbarSettings: {
                enable: false,
            },
            insertImageSettings: {
                saveUrl: `${window.VUE_APP_API_URL}/files/upload-files`,
                path: `${window.VUE_APP_API_URL}/files/`,
            },
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    provide:{
        richtexteditor:[Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar, Table]
    },
    methods: {
        onCreate() {
            var option = {
                rules: {
                    [this.name]: {
                        required: [this.isRequired, i18n.t('AUTH.VALIDATION.REQUIRED')]
                    }
                },
                customPlacement: (element, errorElement) => {
                    document.querySelector(".error-element").appendChild(errorElement);
                },
            };

            this.formObject = new FormValidator(`#${this.formId}`, option);
        },
        onImageUploadSuccess: function(args){
            const token = args.e.currentTarget.getResponseHeader("x-file-download-access-token");
            const fileName = args.e.currentTarget.getResponseHeader("x-file-name");
            if (token && fileName) {
                // rename saved file with the id and download token
                args.file.name = `${fileName}?token=${token}`;
                let filename = document.querySelectorAll(".e-file-name")[0];
                if (filename) {
                    filename.innerHTML = args.file.name.replace(document.querySelectorAll(".e-file-type")[0]?.innerHTML, '');
                    filename.title = args.file.name;
                }
            }
        },
        onImageUploading: function(args){
            const req = args.currentRequest;
            req.setRequestHeader('x-file-access-token', this.uploadToken);
        },
        validate() {
            document.querySelector('.e-rte-content').classList.remove('has-error');

            if (!this.formObject.validate()) {
                document.querySelector('.e-rte-content').classList.add('has-error');
            }
        }
    },
    mounted() {
        this.onCreate();
    }
}
</script>

<style>
@import "~@syncfusion/ej2-base/styles/bootstrap.css";
@import "~@syncfusion/ej2-inputs/styles/bootstrap.css";
@import "~@syncfusion/ej2-lists/styles/bootstrap.css";
@import "~@syncfusion/ej2-popups/styles/bootstrap.css";
@import "~@syncfusion/ej2-buttons/styles/bootstrap.css";
@import "~@syncfusion/ej2-navigations/styles/bootstrap.css";
@import "~@syncfusion/ej2-splitbuttons/styles/bootstrap.css";
@import "~@syncfusion/ej2-vue-richtexteditor/styles/bootstrap.css";

.e-richtexteditor .e-linkheader {
    display: none !important;
}

.imgUrl {
    display: none !important;
}
</style>
